import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validationSchema from './validationSchema';
import { useFormik } from 'formik';
import BasicInput from '../../../components/BasicInput/BasicInput';
import './PollForm.scss';
import BasicButton from '../../../components/Button/Button';
import RateField from './components/RateField/RateField';
import CheckboxGroup from './components/CheckboxGroup/CheckboxGroup';
import {
    isDelayOptions,
    reasonSelectOptions,
    attentionSelectOptions,
    lecturesQuantityOptions,
    lessonsQuantityOptions,
    literatureProvideOptions,
    literatureNeedOptions,
} from './options';
import RadioGroup from './components/RadioGroup/RadioGroup';
import { submitPoll } from '../../../store/actions/pollActions';
import { useParams } from 'react-router';
import { getSurvey } from '../../../store/actions/surveyAction';
import InfoDialog from '../../../components/InfoDialog/InfoDialog';

const PollForm = () => {
    const { surveyId } = useParams();
    const { survey } = useSelector((state) => state.survey);
    const dispatch = useDispatch();
    const [error] = useState(''); // TODO: set error
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const handleClose = () => setIsOpenDialog(false);
    useEffect(() => {
        dispatch(getSurvey(surveyId));
    }, [dispatch, surveyId]);

    const formik = useFormik({
        initialValues: {
            levelRate: '',
            structureRate: '',
            scheduleRate: '',
            hardSkillsRate: '',
            softSkillsRate: '',
            attentionSelect: [],
            reasonSelect: [],
            lecturesQuantity: '',
            lessonsQuantity: '',
            literatureProvide: [],
            literatureNeed: '',
            materialQuantityRate: '',
            toChangeSuggestions: '',
            // isDelay: '',
            disciplineRate: '',
            futureImportanceRate: '',
            isSuggestedRate: '',
            //
            attentionSelectElse: '',
            reasonSelectElse: '',
            literatureProvideElse: '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            let data = values;
            if (values.attentionSelectElse) {
                data.attentionSelect = [values.attentionSelectElse];
            }
            if (values.reasonSelectElse) {
                data.reasonSelect = [values.reasonSelectElse];
            }
            if (values.literatureProvideElse) {
                data.literatureProvide = [values.literatureProvideElse];
            }
            dispatch(submitPoll(values, surveyId));
            setIsOpenDialog(true);
            resetForm({});
            setIsFinished(true);
        },
    });

    const errorsMessage = Object.keys(formik.errors).map((field) => {
        if (formik.touched[field]) {
            return formik.errors[field];
        }
        return '';
    });
    errorsMessage.push(error);

    return (
        <div>
            <InfoDialog
                hideCloseBtn
                open={isOpenDialog}
                onClose={handleClose}
                title="Дякуємо за відповідь!"
            />
            <InfoDialog
                hideCloseBtn
                open={!survey.active}
                title="Опитування вже завершено. Дякуємо!"
            />
            <form className="poll-form" onSubmit={formik.handleSubmit}>
                <div className="poll-form__label-wraper">
                    <span className="field-label">Освітня програма:</span>
                    <span className="field-name">{survey.programName}</span>
                </div>
                <div className="poll-form__label-wraper">
                    <span className="field-label">Дисципліна (освітня компонента):</span>
                    <span className="field-name">{survey.discipline}</span>
                </div>
                <div className="poll-form__label-wraper">
                    <span className="field-label">Викладач:</span>
                    <span className="field-name">{`${survey.user?.lastName} ${survey.user?.firstName} ${survey.user?.patronymic}`}</span>
                </div>
                <RateField
                    setFieldValue={formik.setFieldValue}
                    name="levelRate"
                    label="1. Як Ви можете оцінити рівень володіння матеріалом дисципліни викладачeм?"
                />
                <RateField
                    setFieldValue={formik.setFieldValue}
                    name="structureRate"
                    label="2. Матеріал дисципліни був структурованим, викладався чітко і зрозуміло"
                />
                <RateField
                    setFieldValue={formik.setFieldValue}
                    name="scheduleRate"
                    label="3. Викладач дотримувався розкладу занять та його таймінгу (тривалість занять, час початку та закінчення)"
                />
                <RateField
                    setFieldValue={formik.setFieldValue}
                    name="hardSkillsRate"
                    label="4.Чи опанували Ви в процесі оволодіння матеріалом дисципліни нові навички (hard skills), корисні, з Вашої точки зору, у майбутній професійній та практичній діяльності?"
                />
                <RateField
                    name="softSkillsRate"
                    label="5.Чи сприяли завдання в рамках вивчення дисципліни розвитку Ваших соціальних навичок (soft skills): комунікабельність, креативність, самоорганізація, вміння працювати в команді, стресостійкість тощо?"
                    setFieldValue={formik.setFieldValue}
                />
                <CheckboxGroup
                    name="attentionSelect"
                    values={formik.values.attentionSelect}
                    setFieldValue={formik.setFieldValue}
                    label="6. Чи акцентує увагу викладач під час занять на :"
                    options={attentionSelectOptions}
                    //
                    onChange={formik.handleChange}
                    inputName="attentionSelectElse"
                    inputValue={formik.values.attentionSelectElse}
                />
                <CheckboxGroup
                    name="reasonSelect"
                    values={formik.values.reasonSelect}
                    setFieldValue={formik.setFieldValue}
                    label="7. Вивчення дисципліни було пов’язане з (можливо декілька відповідей):"
                    options={reasonSelectOptions}
                    //
                    onChange={formik.handleChange}
                    inputName="reasonSelectElse"
                    inputValue={formik.values.reasonSelectElse}
                />
                <RadioGroup
                    setFieldValue={formik.setFieldValue}
                    name="lecturesQuantity"
                    label="8. Чи достатньою, на Вашу думку, була кількість лекцій для опанування дисципліни?"
                    options={lecturesQuantityOptions}
                />
                <RadioGroup
                    name="lessonsQuantity"
                    label="9. Чи достатньою, на Вашу думку, була кількість практичних/лабораторних занять для опанування дисципліни?"
                    setFieldValue={formik.setFieldValue}
                    options={lessonsQuantityOptions}
                />
                <CheckboxGroup
                    name="literatureProvide"
                    label="10. Чи забезпечена дисципліна навчально-методичною літературою? (можливо декілька відповідей)"
                    setFieldValue={formik.setFieldValue}
                    options={literatureProvideOptions}
                    values={formik.values.literatureProvide}
                    //
                    onChange={formik.handleChange}
                    inputName="literatureProvideElse"
                    inputValue={formik.values.literatureProvideElse}
                />
                <RadioGroup
                    name="literatureNeed"
                    label="11. Чи виникала у Вас потреба звертатись до навчально-методичної літератури в процесі вивчення дисципліни?"
                    setFieldValue={formik.setFieldValue}
                    options={literatureNeedOptions}
                />
                <RateField
                    name="materialQuantityRate"
                    label="12. Чи в достатній мірі наповнено курс дисципліни в дистанційному середовищі для опанування нею?"
                    setFieldValue={formik.setFieldValue}
                />
                <BasicInput
                    isBoldLabel
                    type="text"
                    name="toChangeSuggestions"
                    label="13. Якби була можливість, щоб Ви змінили у викладанні дисципліни?"
                    margin="normal"
                    value={formik.values.toChangeSuggestions}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.toChangeSuggestions &&
                        Boolean(formik.errors.toChangeSuggestions)
                    }
                />
                <RadioGroup
                    name="literatureNeed"
                    label="14. Чи були для Вас чіткими та зрозумілими критерії оцінювання успішності за дисципліною?"
                    setFieldValue={formik.setFieldValue}
                    options={isDelayOptions}
                />
                <RateField
                    name="disciplineRate"
                    label="15. Як би Ви в цілому оцінили рівень викладання дисципліни?"
                    setFieldValue={formik.setFieldValue}
                />
                <RateField
                    name="futureImportanceRate"
                    label="16.Чи важливе, на Вашу думку, для вашої майбутньої професії вивчення даної дисципліни?"
                    setFieldValue={formik.setFieldValue}
                />
                <RateField
                    name="isSuggestedRate"
                    label="17. Чи порадили б дану дисципліну студентам, які навчаються за іншими освітніми програмами?"
                    setFieldValue={formik.setFieldValue}
                />

                <p className="error-field">{errorsMessage[0]}</p>
                <BasicButton
                    disabled={!survey.active || isFinished}
                    className="poll-form__submit-btn"
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                >
                    Відправити форму
                </BasicButton>
            </form>
        </div>
    );
};

export default React.memo(PollForm);
