import {
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    makeStyles,
    Container,
    IconButton,
} from '@material-ui/core';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { AuthContext } from '../../context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteSurvey,
    getActiveSurveys,
    getArchiveSurvey,
    restartSurvey,
    stopSurvey,
} from '../../store/actions/surveyAction';
import { useHistory } from 'react-router';
import ReportOffIcon from '@material-ui/icons/ReportOff';
import { blockUser, getUserData, updateUserData } from '../../store/actions/userActions';
import { getAllUsers } from '../../store/actions/usersActions';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import BackdropLoader from '../BackdropLoader/BackdropLoader';
import ErrorSnackBar from '../ErrorSnackBar/ErrorSnackBar';
import { getExcelReport, getChartReport } from '../../store/actions/reportActions';

const useStyles = makeStyles((theme) => ({
    table: {
        borderRadius: '5px',
        border: 'solid 1px #C4C4C4',
        borderCollapse: 'inherit',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    cell: {
        textAlign: 'center',
        fontSize: '14px',
        height: 40,
    },
    container: {
        margin: theme.spacing(1),
    },
    linkIcon: {
        width: '50px',
        fontSize: '25px',
        color: 'rgba(130, 130, 130, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    shareIcon: {
        fontSize: '25px',
        color: 'rgba(45, 156, 219, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    stopIcon: {
        width: '50px',
        fontSize: '25px',
        color: 'rgba(235, 87, 87, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    accountIcon: {
        width: '50px',
        fontSize: '25px',
        color: 'rgba(51, 51, 51, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    helpIcon: {
        fontSize: '25px',
        color: 'rgba(51, 51, 51, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    reportIcon: {
        fontSize: '25px',
        color: 'rgba(39, 174, 96, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    reportOffIcon: {
        fontSize: '25px',
        color: 'rgba(235, 87, 87, 1)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headerActiveHub: {
        backgroundColor: 'rgba(39, 174, 96, 0.35)',
    },
    headerDisableHub: {
        backgroundColor: 'rgba(45, 156, 219, 0.35)',
    },
    headerTeacherHub: {
        backgroundColor: 'rgba(39, 174, 96, 0.35)',
    },
    headerFinishHub: {
        backgroundColor: 'rgba(45, 156, 219, 0.35)',
    },
    bodyActiveHub: {
        '&:hover': {
            backgroundColor: 'rgba(39, 174, 96, 0.15)',
        },
    },
    bodyDisableHub: {
        '&:hover': {
            backgroundColor: 'rgba(45, 156, 219, 0.15)',
        },
    },
    bodyTeacherHub: {
        '&:hover': {
            backgroundColor: 'rgba(235, 87, 87, 0.15)',
        },
    },
    bodyFinishHub: {
        '&:hover': {
            backgroundColor: 'rgba(45, 156, 219, 0.15)',
        },
    },
}));

const copyLink = (id) => {
    const str = `${document.location.origin}/survey/${id}`;
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

function TableHeader(props) {
    const classes = useStyles();
    const heads = Object.values(props.headers);
    heads.unshift('№');
    if (props.typeTable === 'activeHub') heads.push('Керування');
    if (props.typeTable === 'teacherHub') heads.push('Профіль', 'Опитування', 'Доступ');
    const cells = heads.map((head) => {
        return (
            <TableCell
                key={head.toUpperCase()}
                className={classes.cell}
                variant={'head'}
                size={'small'}
            >
                {head.toUpperCase()}
            </TableCell>
        );
    });
    const classStyle =
        props.typeTable === 'activeHub'
            ? classes.headerActiveHub
            : props.typeTable === 'finishHub'
            ? classes.headerDisableHub
            : props.typeTable === 'teacherHub'
            ? classes.headerTeacherHub
            : classes.headerFinishHub;

    return (
        <TableHead>
            <TableRow className={classStyle}>{cells}</TableRow>
        </TableHead>
    );
}

const createActiveRow = (row, className) => {
    return (
        <>
            <TableCell variant={'body'} size={'small'} className={className}>
                {moment(row.createdAt).format('DD-MM-YYYY')}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.programName}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.discipline}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.group}
            </TableCell>
        </>
    );
};

const createArchiveRow = (row, className) => {
    return (
        <>
            <TableCell variant={'body'} size={'small'} className={className}>
                {moment(row.createdAt).format('DD-MM-YYYY')}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.active || row.endDate === null
                    ? '-'
                    : moment(row.endDate).format('DD-MM-YYYY')}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.programName}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.discipline}
            </TableCell>
            <TableCell variant={'body'} size={'small'} className={className}>
                {row.group}
            </TableCell>
        </>
    );
};

function TableBodyFields(props) {
    const classes = useStyles();
    const auth = useContext(AuthContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedSurveyId, setSelectedSurveyId] = useState(null);
    const [selecttedUser, setSelecttedUser] = useState(null);
    const [restartSurveyId, setRestartSurveyId] = useState(null);
    const [toDeleteSurveyId, setToDeleteSurveyId] = useState(null);
    const { loading, error } = useSelector((state) => state.survey);

    useEffect(() => {
        if (!loading && !error) {
            dispatch(getAllUsers(auth.token));
        }
    }, [dispatch, auth.token, loading, error]);

    const handleBlockUser = (user) => {
        setSelecttedUser(user);
    };

    const handleCloseBlok = () => {
        setSelecttedUser(null);
    };

    const handleOpen = (id) => {
        setSelectedSurveyId(id);
    };
    const handleClose = () => {
        setSelectedSurveyId(null);
    };
    const handleConfirmClose = () => {
        dispatch(stopSurvey(selectedSurveyId, auth.token));
        dispatch(getActiveSurveys(auth.token));
        dispatch(getArchiveSurvey(auth.token));

        handleClose();
    };

    const handleConfirmBlock = () => {
        dispatch(blockUser(selecttedUser._id, auth.token));
        // dispatch(getAllUsers(auth.token));
        handleCloseBlok();
    };

    //
    const handleRestartSurvey = (id) => {
        setRestartSurveyId(id);
    };
    const handleDeleteSurvey = (id) => {
        setToDeleteSurveyId(id);
    };

    const handleCloseRestartSurvey = () => {
        setRestartSurveyId(null);
    };
    const handleCloseDeleteSurvey = () => {
        setToDeleteSurveyId(null);
    };
    const handleConfirmRestartSurvey = () => {
        dispatch(restartSurvey(restartSurveyId.surveyId, auth.token));
        dispatch(getUserData(restartSurveyId.userId, auth.token));
        handleCloseRestartSurvey();
    };
    const handleConfirmDeleteSurvey = () => {
        dispatch(deleteSurvey(toDeleteSurveyId.surveyId, auth.token));
        dispatch(getUserData(toDeleteSurveyId.userId, auth.token));
        handleCloseDeleteSurvey();
    };
    //

    let masCells = [];
    if (props.page >= 1) {
        if (props.fields.length <= props.page * props.limitShowRow) {
            masCells = props.fields.slice(
                props.fields.indexOf(props.fields[(props.page - 1) * props.limitShowRow]),
                props.fields.length,
            );
        } else {
            masCells = props.fields.slice(
                props.fields.indexOf(props.fields[(props.page - 1) * props.limitShowRow]),
                props.page * props.limitShowRow,
            );
        }
    }
    const rows = masCells.map((row) => {
        const masCells = [];
        for (const key in row) {
            if (key) masCells.push(row[key]);
        }
        const classStyle =
            props.typeTable === 'activeHub'
                ? classes.bodyActiveHub
                : props.typeTable === 'disableHub'
                ? classes.bodyDisableHub
                : props.typeTable === 'teacherHub'
                ? classes.bodyTeacherHub
                : classes.bodyFinishHub;
        if (props.typeTable === 'teacherHub') {
            return (
                <TableRow key={props.fields.indexOf(row) + 1} className={classStyle}>
                    <TableCell
                        key={props.fields.indexOf(row) + 1}
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                    >
                        {props.fields.indexOf(row) + 1}
                    </TableCell>
                    <TableCell variant={'body'} size={'small'} className={classes.cell}>
                        {`${row.lastName} ${row.firstName[0]}.${row.patronymic[0]}.`}
                    </TableCell>
                    <TableCell variant={'body'} size={'small'} className={classes.cell}>
                        {row.email}
                    </TableCell>

                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'5px'}
                    >
                        <IconButton
                            onClick={() => history.push(`/edit/${row._id}`)}
                            className={classes.linkIcon}
                        >
                            <AccountCircleRoundedIcon className={classes.accountIcon} />
                        </IconButton>
                    </TableCell>
                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'5px'}
                    >
                        <IconButton
                            onClick={() => history.push(`/users/${row._id}`)}
                            className={classes.linkIcon}
                        >
                            <HelpRoundedIcon className={classes.helpIcon} />
                        </IconButton>
                    </TableCell>
                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'5px'}
                    >
                        <IconButton
                            onClick={() => handleBlockUser(row)}
                            className={classes.linkIcon}
                        >
                            {!row.active ? (
                                <ReportOffIcon />
                            ) : (
                                <ReportRoundedIcon className={classes.reportIcon} />
                            )}
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        if (props.typeTable === 'finishHub') {
            return (
                <TableRow key={props.fields.indexOf(row) + 1} className={classStyle}>
                    <TableCell
                        key={props.fields.indexOf(row) + 1}
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                    >
                        {props.fields.indexOf(row) + 1}
                    </TableCell>
                    {createArchiveRow(row, classes.cell)}
                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'200px'}
                    >
                        <IconButton
                            onClick={() => dispatch(getExcelReport(row, auth.token))}
                            className={classes.linkIcon}
                        >
                            <InsertDriveFileIcon className={classes.reportIcon} />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                window.open(`/charts/${row._id}`, '_blank').focus();
                            }}
                            className={classes.linkIcon}
                        >
                            <InsertChartIcon className={classes.reportIcon} />
                        </IconButton>
                    </TableCell>
                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'200px'}
                    >
                        {/* {row.createdAt < row.endDate && ( */}
                        <IconButton
                            onClick={() =>
                                handleRestartSurvey({ surveyId: row._id, userId: row.user._id })
                            }
                        >
                            <RestoreIcon color="action" />
                        </IconButton>
                        {/* )} */}
                        <IconButton
                            onClick={() =>
                                handleDeleteSurvey({ surveyId: row._id, userId: row.user._id })
                            }
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        if (props.typeTable === 'activeHub') {
            return (
                <TableRow key={props.fields.indexOf(row) + 1} className={classStyle}>
                    <TableCell
                        key={props.fields.indexOf(row) + 1}
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                    >
                        {props.fields.indexOf(row) + 1}
                    </TableCell>
                    {createActiveRow(row, classes.cell)}
                    <TableCell
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                        width={'200px'}
                    >
                        <IconButton onClick={() => copyLink(row._id)} className={classes.linkIcon}>
                            <LinkRoundedIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleOpen(row._id)}
                            className={classes.stopIcon}
                        >
                            <StopRoundedIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow key={props.fields.indexOf(row) + 1} className={classStyle}>
                    <TableCell
                        key={props.fields.indexOf(row) + 1}
                        variant={'body'}
                        size={'small'}
                        className={classes.cell}
                    >
                        {props.fields.indexOf(row) + 1}
                    </TableCell>
                    {createArchiveRow(row, classes.cell)}
                </TableRow>
            );
        }
    });
    return (
        <>
            <ConfirmationDialog
                open={!!selectedSurveyId}
                title={'ВИ ДІЙНО БАЖАЄТЕ ЗАВЕРШИТИ ЦЕ ОПИТУВАННЯ?'}
                onClose={handleClose}
                onConfirm={handleConfirmClose}
            />
            <ConfirmationDialog
                open={!!selecttedUser}
                title={
                    selecttedUser?.active
                        ? 'ВИ ДІЙНО БАЖАЄТЕ БЛОКУВАТИ ЦЬОГО ВИКЛАДАЧА?'
                        : 'ВИ ДІЙНО БАЖАЄТЕ ВІДНОВИТИ ЦЬОГО ВИКЛАДАЧА?'
                }
                onClose={handleCloseBlok}
                onConfirm={handleConfirmBlock}
            />
            <ConfirmationDialog
                open={!!restartSurveyId}
                title={'ВИ ДІЙНО БАЖАЄТЕ ВІДНОВИТИ ОПИТУВАННЯ?'}
                onClose={handleCloseRestartSurvey}
                onConfirm={handleConfirmRestartSurvey}
            />

            <ConfirmationDialog
                open={!!toDeleteSurveyId}
                title={'ВИ ДІЙНО БАЖАЄТЕ ВИДАЛИТИ ЦЕ ОПИТУВАННЯ?'}
                onClose={handleCloseDeleteSurvey}
                onConfirm={handleConfirmDeleteSurvey}
            />

            <TableBody>{rows}</TableBody>
        </>
    );
}

function PaginationButtons(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Pagination
                className={classes.pagination}
                variant={'text'}
                count={props.countPage}
                page={props.page}
                onChange={props.handleChange}
                showFirstButton
                showLastButton
            />
        </React.Fragment>
    );
}

export default function FullTable(props) {
    const classes = useStyles();
    const { loading: loadingSurvey, error: errorSurvey } = useSelector((state) => state.survey);
    const { loading: loadingReport, error: errorReport } = useSelector((state) => state.report);

    return (
        <Container className={classes.container}>
            <ErrorSnackBar error={errorSurvey || errorReport} />
            <BackdropLoader isOpen={loadingSurvey || loadingReport} />
            <Table className={classes.table}>
                <TableHeader headers={props.headers} typeTable={props.typeTable}></TableHeader>
                <TableBodyFields
                    fields={props.fields}
                    page={props.page}
                    limitShowRow={props.limitShowRow}
                    typeTable={props.typeTable}
                />
            </Table>
            <PaginationButtons
                page={props.page}
                countPage={props.countPage}
                handleChange={props.handleChange}
            />
        </Container>
    );
}
