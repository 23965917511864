import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PageSubtitle from '../../components/PageTitle/PageSubtitle';
import PageTitle from '../../components/PageTitle/PageTitle';
import { FINISHED_TABLE_HEAD_DATA } from '../../components/Table/headers';
import FullTable from '../../components/Table/Table';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import { AuthContext } from '../../context/AuthContext';
import { pageCounter } from '../../hooks/mySurvey.helper';
import { getUserData } from '../../store/actions/userActions';

const AdminUserSurveysPage = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { profile } = useSelector((state) => state.user);
    const [page, setPage] = useState(1);
    const handleChangeActivePage = (e, page) => {
        setPage(page);
    };
    useEffect(() => {
        dispatch(getUserData(userId, auth.token));
    }, [dispatch, userId, auth]);
    return (
        <WithMainSidebar noBottomDecoration>
            <div className="container">
                <PageTitle text="Опитування викладача" />
                <PageSubtitle
                    text={`${profile.lastName} ${profile.firstName} ${profile.patronymic}`}
                />
                <FullTable
                    handleChange={handleChangeActivePage}
                    page={page}
                    limitShowRow={10}
                    countPage={pageCounter(profile.surveys.length, 10)}
                    fields={profile.surveys}
                    headers={FINISHED_TABLE_HEAD_DATA}
                    typeTable="finishHub"
                />
            </div>
        </WithMainSidebar>
    );
};

export default AdminUserSurveysPage;
