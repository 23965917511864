import { combineReducers } from 'redux';
import { singupReducer } from './singupReducer';
import { userReducer } from './userReducer';
import { surveyReducer } from './surveyReducer';
import { usersReducer } from './usersReducer';
import { reportReducer } from './reportReducer';

export const rootReducer = combineReducers({
    user: userReducer,
    singup: singupReducer,
    survey: surveyReducer,
    users: usersReducer,
    report: reportReducer,
});
