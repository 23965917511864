import React from 'react';
import './PublicUserPage.scss';
import PollForm from './PollForm/PollForm';
import './PublicUserPage.scss';

const PublicUserPage = () => {
    return (
        <div className="public-page">
            <div className="container">
                <h1 className="title">АНКЕТА</h1>
                <h2 className="subtitle">
                    Просимо взяти участь у обговоренні якості викладання дисципліни (освітньої
                    компоненти) з метою її покращення в майбутньому
                </h2>
                <p className="description">
                    Анкетування є добровільним і анонімним Заздалегідь дякуємо за допомогу!
                </p>

                <PollForm />
            </div>
        </div>
    );
};

export default PublicUserPage;
