import React, { useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import BasicInput from '../../components/BasicInput/BasicInput';
import BasicButton from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import { getProfileData, updateProfileData } from '../../store/actions/userActions';
import Loader from '../Loader/Loader';
import ErrorSnackBar from '../ErrorSnackBar/ErrorSnackBar';

const useStyles = makeStyles(() => ({
    submitBtn: {
        marginTop: 40,
    },
}));

const validationSchema = yup.object({
    firstName: yup
        .string(`Введіть ваше і'мя`)
        .min(2, `Мінімальна і'мя довжина 2 символи`)
        .max(180, 'Максимальна довжина 180 символів'),
    lastName: yup
        .string('Введіть прізвище')
        .min(2, 'Мінімальна прізвища довжина 2 символи')
        .max(180, 'Максимальна прізвища довжина 180 символів'),
    patronymic: yup
        .string('Введіть По-батькові')
        .min(2, 'Мінімальна По-батькові довжина 2 символи')
        .max(180, 'Максимальна По-батькові довжина 180 символів'),
    email: yup.string('Введіть електронну пошту').email('Введіть валідну електронну пошту'),
    newPassword: yup
        .string('Введіть новий пароль')
        .min(8, 'Пароль повинен містити не менше 8 символів'),
    newPasswordConfirm: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Паролі повинні збігатися'),
    password: yup
        .string('Введіть пароль')
        .min(8, 'Пароль повинен містити не менше 8 символів')
        .required(`Пароль є обовя'зковим`),
});

const ProfileForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { loading, error, profile } = useSelector((state) => state.user);

    const getUser = useCallback(() => {
        dispatch(getProfileData(auth.token));
    }, [auth.token, dispatch]);
    const updateUser = useCallback(
        (data) => {
            dispatch(updateProfileData(data, auth.token));
        },
        [auth.token, dispatch],
    );

    useEffect(() => {
        getUser();
    }, [getUser]);
    const initialValues = {
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        patronymic: profile.patronymic || '',
        email: profile.email || '',
        newPassword: '',
        newPasswordConfirm: '',
        password: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (value, { resetForm }) => {
            updateUser(value);
            resetForm(initialValues);
        },
    });
    if (loading) {
        return <Loader />;
    }

    const errorsMessage = Object.keys(formik.errors).map((field) => {
        if (formik.touched[field]) {
            return formik.errors[field];
        }
        return '';
    });

    return (
        <>
            <ErrorSnackBar error={error} />
            <form onSubmit={formik.handleSubmit}>
                <BasicInput
                    type="text"
                    name="lastName"
                    label="Прізвище"
                    margin="normal"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                />
                <BasicInput
                    type="text"
                    name="firstName"
                    label="ім'я"
                    margin="normal"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                />
                <BasicInput
                    type="text"
                    name="patronymic"
                    label="По-батькові"
                    margin="normal"
                    value={formik.values.patronymic}
                    onChange={formik.handleChange}
                    error={formik.touched.patronymic && Boolean(formik.errors.patronymic)}
                />
                <BasicInput
                    type="email"
                    name="email"
                    label="Електронна пошта"
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <BasicInput
                    name="newPassword"
                    label="Новий пароль"
                    type="password"
                    placeholder="Залиште без змін, якщо не бажаєте змінювати пароль"
                    margin="dense"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                />
                <BasicInput
                    name="newPasswordConfirm"
                    label="Повторіть новий пароль"
                    placeholder="Залиште без змін, якщо не бажаєте змінювати пароль"
                    type="password"
                    margin="dense"
                    value={formik.values.newPasswordConfirm}
                    onChange={formik.handleChange}
                />
                <BasicInput
                    name="password"
                    label="Для збереження змін введіть поточний пароль"
                    type="password"
                    margin="normal"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                />
                <p className="error-field">{errorsMessage[0]}</p>

                <BasicButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.submitBtn}
                    fullWidth
                >
                    Зберегти
                </BasicButton>
            </form>
        </>
    );
};

export default ProfileForm;
