// profile data
export const GET_PROFILE_DATA_START = 'GET_PROFILE_DATA_START';
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAIL = 'GET_PROFILE_DATA_FAIL';

export const UPDATE_PROFILE_DATA_START = 'UPDATE_PROFILE_DATA_START';
export const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA_SUCCESS';
export const UPDATE_PROFILE_DATA_FAIL = 'UPDATE_PROFILE_DATA_FAIL';

export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const BLOCK_USER_START = 'BLOCK_USER_START';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

// singup data
export const SET_SINGUP_DATA = 'SET_SINGUP_DATA';
export const GET_SINGUP_DATA = 'GET_SINGUP_DATA';

// survey
export const CREATE_SURVEY_START = 'CREATE_SURVEY_START';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAIL = 'CREATE_SURVEY_FAIL';
export const SURVEY_RESER = 'SURVEY_RESER';

export const GET_SURVEY_START = 'GET_SURVEY_START';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAIL = 'GET_SURVEY_FAIL';

export const GET_ACTIVE_SURVEY_START = 'GET_ACTIVE_SURVEY_START';
export const GET_ACTIVE_SURVEY_SUCCESS = 'GET_ACTIVE_SURVEY_SUCCESS';
export const GET_ACTIVE_SURVEY_FAIL = 'GET_ACTIVE_SURVEY_FAIL';

export const GET_ARCHIVE_SURVEY_START = 'GET_ARCHIVE_SURVEY_START';
export const GET_ARCHIVE_SURVEY_SUCCESS = 'GET_ARCHIVE_SURVEY_SUCCESS';
export const GET_ARCHIVE_SURVEY_FAIL = 'GET_ARCHIVE_SURVEY_FAIL';

export const STOP_SURVEY_START = 'STOP_SURVEY_START';
export const STOP_SURVEY_SUCCESS = 'STOP_SURVEY_SUCCESS';
export const STOP_SURVEY_FAIL = 'STOP_SURVEY_FAIL';

export const DELETE_SURVEY_START = 'DELETE_SURVEY_START';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_FAIL = 'DELETE_SURVEY_FAIL';

export const RESTART_SURVEY_START = 'RESTART_SURVEY_START';
export const RESTART_SURVEY_SUCCESS = 'RESTART_SURVEY_SUCCESS';
export const RESTART_SURVEY_FAIL = 'RESTART_SURVEY_FAIL';

// public poll
export const SUBMIT_POLL_START = 'SUBMIT_POLL_START';
export const SUBMIT_POLL_SUCCESS = 'SUBMIT_POLL_SUCCESS';
export const SUBMIT_POLL_FAIL = 'SUBMIT_POLL_FAIL';

//report
export const GET_REPORT_EXCEL_START = 'GET_REPORT_EXCEL_START';
export const GET_REPORT_EXCEL_SUCCESS = 'GET_REPORT_EXCEL_SUCCESS';
export const GET_REPORT_EXCEL_FAIL = 'GET_REPORT_EXCEL_FAIL';

export const GET_CHART_START = 'GET_CHART_START';
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHART_FAIL = 'GET_CHART_FAIL';

export const GET_REPORT_PARAMS_START = 'GET_REPORT_PARAMS_START';
export const GET_REPORT_PARAMS_SUCCESS = 'GET_REPORT_PARAMS_SUCCESS';
export const GET_REPORT_PARAMS_FAIL = 'GET_REPORT_PARAMS_FAIL';

export const GET_PERIOD_REPORT_START = 'GET_PERIOD_REPORT_START';
export const GET_PERIOD_REPORT_SUCCESS = 'GET_PERIOD_REPORT_SUCCESS';
export const GET_PERIOD_REPORT_FAIL = 'GET_PERIOD_REPORT_FAIL';
