import * as actionTypes from '../actionTypes';

const initialState = {
    loading: false,
    error: null,
    chartData: { data: [], survey: {} },
    periodReportParams: { programNames: [], minDate: new Date(), maxDate: new Date() },
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REPORT_PARAMS_START:
        case actionTypes.CREATE_SURVEY_START:
        case actionTypes.GET_CHART_START:
            return { ...state, loading: true, error: null };

        case actionTypes.GET_REPORT_PARAMS_FAIL:
        case actionTypes.GET_SURVEY_FAIL:
            return { ...state, loading: false, error: action.payload };
        case actionTypes.GET_REPORT_PARAMS_SUCCESS:
            return { ...state, loading: false, error: null, periodReportParams: action.payload };
        case actionTypes.GET_ARCHIVE_SURVEY_SUCCESS:
            return { ...state, loading: false, error: null };
        case actionTypes.GET_CHART_SUCCESS:
            return { ...state, loading: false, error: null, chartData: action.payload };

        case actionTypes.GET_SURVEY_FAIL:
        case actionTypes.GET_CHART_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};
