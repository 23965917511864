import React, { useState } from 'react';
import {
    createStyles,
    fade,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    InputBase,
    makeStyles,
    withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Visibility } from '../../assets/icons/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../assets/icons/visibility_off.svg';

const BootstrapInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: '5px',
            position: 'relative',
            backgroundColor: '#F2F2F2',
            border: '1px solid transparent',
            fontSize: 18,
            width: '100%',
            height: 20,
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),

            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: '#2D9CDB',
            },
        },
    }),
)(InputBase);

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: '16px',
            fontWeight: 'bold',
        },
        wrapper: {
            width: '100%',
        },
        label: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#000',
            marginBottom: 8,
        },
        inputAdornment: {
            position: 'absolute',
            right: 0,
        },
        inputPassword: {
            '& input': { paddingRight: 50 },
        },
    }),
);

const BasicInput = ({
    label = '',
    margin,
    type,
    className,
    isBoldLabel = false,
    noAutocomplete = false,
    ...inputProps
}) => {
    const isPassword = type === 'password';
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(isPassword ? false : true);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl margin={margin} className={classes.wrapper}>
            <FormLabel component="legend" className={isBoldLabel ? classes.label : ''}>
                {label}
            </FormLabel>
            <BootstrapInput
                type={!isPassword ? type : showPassword ? 'text' : 'password'}
                autoComplete={noAutocomplete ? 'off' : 'on'}
                {...inputProps}
                className={clsx(isPassword ? classes.inputPassword : '', className)}
                endAdornment={
                    isPassword && (
                        <InputAdornment className={classes.inputAdornment}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }
            />
        </FormControl>
    );
};

export default BasicInput;
