import axios from 'axios';
import { actionLogout } from '../hooks/auth.hook';

const instance = axios.create();

// setup a retry function for the same request if the server is down, error code 518
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (
            error.response &&
            (error.response.status === 401 || error.response.data.message === 'jwt expired')
        ) {
            actionLogout();
        } else {
            return Promise.reject(error);
        }
    },
);

export default instance;
