import React from 'react';
import { Link } from 'react-router-dom';
import BasicButton from '../Button/Button';

const LinkButton = ({ href = '#', label = '', children, ...btnProps }) => {
    return (
        <div className={`link-button`}>
            <Link to={href}>
                <BasicButton {...btnProps}>{label ? label : children}</BasicButton>
            </Link>
        </div>
    );
};

export default LinkButton;
