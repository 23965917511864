import React, { useRef } from 'react';
import BasicInput from '../BasicInput/BasicInput';
import PageSubtitle from '../PageTitle/PageSubtitle';
import PageTitle from '../PageTitle/PageTitle';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy_icon.svg';
import BasicButton from '../Button/Button';
import { useSelector } from 'react-redux';
import LinkButton from '../LinkButton/LinkButton';

const SuccessCreated = () => {
    const copyInput = useRef();
    const { survey } = useSelector((state) => state.survey);

    const copyText = () => {
        const input = copyInput.current;
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    return (
        <div className="success-created container ">
            <PageTitle text="Опитування створено" />
            <PageSubtitle text="Ваше опитування було успішно створенно!" />
            <BasicInput
                inputRef={copyInput}
                className="success-created__input"
                value={`${document.location.origin}/survey/${survey._id}`}
            />
            <div className="success-created__copy-wrapper">
                <BasicButton onClick={copyText} className="success-created__copy-btn">
                    <CopyIcon />
                    <p className="success-created__copy-text">Копіювати</p>
                </BasicButton>
            </div>
            <LinkButton href='/surveys' variant="outlined" color="secondary" fullWidth>
                Повернутись до Опитувань
            </LinkButton>
        </div>
    );
};

export default SuccessCreated;
