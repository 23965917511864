import instance from '../../api/axiosApi';
import * as actionTypes from '../actionTypes';

const createSurveyStart = () => ({
    type: actionTypes.CREATE_SURVEY_START,
});

const createSurveySuccess = (survey = {}) => ({
    type: actionTypes.CREATE_SURVEY_SUCCESS,
    payload: survey,
});

const createSurveyFail = (error) => ({
    type: actionTypes.CREATE_SURVEY_FAIL,
    payload: error,
});

export const surveyReset = () => ({
    type: actionTypes.SURVEY_RESER,
});

export const createSyrvey = (values, token) => {
    return async (dispatch) => {
        dispatch(createSurveyStart());
        try {
            const res = await instance.post('/api/v1/surveys/', values, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.data; // don't ask why

            dispatch(createSurveySuccess(data));
        } catch (error) {
            dispatch(createSurveyFail(error));
        }
    };
};

const getSurveyStart = () => ({
    type: actionTypes.GET_SURVEY_START,
});

const getSurveySuccess = (survey = {}) => ({
    type: actionTypes.GET_SURVEY_SUCCESS,
    payload: survey,
});

const getSurveyFail = (error) => ({
    type: actionTypes.GET_SURVEY_FAIL,
    payload: error,
});

export const getSurvey = (id) => {
    return async (dispatch) => {
        dispatch(getSurveyStart());
        try {
            const res = await instance.get(`/api/v1/surveys/${id}`, {});
            const data = res.data.data.data; // don't ask why

            dispatch(getSurveySuccess(data));
        } catch (error) {
            dispatch(getSurveyFail(error));
        }
    };
};

const getActiveSurveysStart = () => ({
    type: actionTypes.GET_ACTIVE_SURVEY_START,
});

const getActiveSurveysSuccess = (survey = {}) => ({
    type: actionTypes.GET_ACTIVE_SURVEY_SUCCESS,
    payload: survey,
});

const getActiveSurveysFail = (error) => ({
    type: actionTypes.GET_ACTIVE_SURVEY_FAIL,
    payload: error,
});

export const getActiveSurveys = (token) => {
    return async (dispatch) => {
        dispatch(getActiveSurveysStart());
        try {
            const res = await instance.get(`/api/v1/surveys/me?active=true`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.surveys; // don't ask why

            dispatch(getActiveSurveysSuccess(data));
        } catch (error) {
            dispatch(getActiveSurveysFail(error));
        }
    };
};

const getArchiveSurveysStart = () => ({
    type: actionTypes.GET_ACTIVE_SURVEY_START,
});

const getArchiveSurveysSuccess = (survey = {}) => ({
    type: actionTypes.GET_ARCHIVE_SURVEY_SUCCESS,
    payload: survey,
});

const getArchiveSurveysFail = (error) => ({
    type: actionTypes.GET_ARCHIVE_SURVEY_FAIL,
    payload: error,
});

export const getArchiveSurvey = (token) => {
    return async (dispatch) => {
        dispatch(getArchiveSurveysStart());
        try {
            const res = await instance.get(`/api/v1/surveys/me?active=false`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.surveys; // don't ask why

            dispatch(getArchiveSurveysSuccess(data));
        } catch (error) {
            dispatch(getArchiveSurveysFail(error));
        }
    };
};

const stopSurveyStart = () => ({
    type: actionTypes.STOP_SURVEY_START,
});
const stopSurveySuccess = (survey) => ({
    type: actionTypes.STOP_SURVEY_SUCCESS,
    payload: survey,
});
const stopSurveyFail = (error) => ({
    type: actionTypes.STOP_SURVEY_FAIL,
    payload: error,
});

export const stopSurvey = (id, token) => {
    return async (dispatch) => {
        dispatch(stopSurveyStart());
        try {
            const res = await instance.delete(`/api/v1/surveys/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.data; // don't ask why

            dispatch(stopSurveySuccess(data));
        } catch (error) {
            dispatch(stopSurveyFail(error));
        }
    };
};

const deleteSurveyStart = () => ({
    type: actionTypes.DELETE_SURVEY_START,
});
const deleteSurveySuccess = (survey) => ({
    type: actionTypes.DELETE_SURVEY_SUCCESS,
    payload: survey,
});
const deleteSurveyFail = (error) => ({
    type: actionTypes.DELETE_SURVEY_FAIL,
    payload: error,
});

export const deleteSurvey = (id, token) => {
    return async (dispatch) => {
        dispatch(deleteSurveyStart());
        try {
            await instance.delete(`/api/v1/surveys/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(deleteSurveySuccess({}));
        } catch (error) {
            dispatch(deleteSurveyFail(error));
        }
    };
};

const restartSurveyStart = () => ({
    type: actionTypes.RESTART_SURVEY_START,
});
const restartSurveySuccess = (survey) => ({
    type: actionTypes.RESTART_SURVEY_SUCCESS,
    payload: survey,
});
const restartSurveyFail = (error) => ({
    type: actionTypes.RESTART_SURVEY_FAIL,
    payload: error,
});

export const restartSurvey = (id, token) => {
    return async (dispatch) => {
        dispatch(restartSurveyStart());
        try {
            const data = await instance.get(`/api/v1/surveys/restart/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(restartSurveySuccess(data));
        } catch (error) {
            dispatch(restartSurveyFail(error));
        }
    };
};
