import React, { useContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEACHER_TABLE_HEAD_DATA } from '../../components/Table/headers';
import FullTable from '../../components/Table/Table';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import { getAllUsers } from '../../store/actions/usersActions';
import { AuthContext } from '../../context/AuthContext';

const pageCounter = (length, limit) => {
    return Math.ceil(length / limit);
};

const AdminTeachersPage = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const { users, loading } = useSelector((state) => state.users);
    const [page, setPage] = useState(1);
    const getAllUsersHandler = useCallback(() => {
        dispatch(getAllUsers(auth.token));
    }, [dispatch, auth.token]);

    useEffect(() => {
        if (!loading) {
            getAllUsersHandler();
        }
    }, [getAllUsersHandler, page]);

    const handleChangeActivePage = (e, page) => {
        setPage(page);
    };

    return (
        <WithMainSidebar noBottomDecoration>
            <div className="my-survey">
                <div className="my-survey__header">
                    <h2 className="my-survey__title">Перелік викладачів</h2>
                </div>
                <FullTable
                    handleChange={handleChangeActivePage}
                    page={page}
                    limitShowRow={10}
                    countPage={pageCounter(users.length, 10)}
                    fields={users}
                    headers={TEACHER_TABLE_HEAD_DATA}
                    typeTable="teacherHub"
                />
            </div>
        </WithMainSidebar>
    );
};

export default AdminTeachersPage;
