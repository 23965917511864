import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarTitle from '../../../SidebarStyles/SidebarTitle';
import { SidebarBtnStyles, SidebarBtnExitStyles } from '../../../SidebarStyles/SidebarBtnStyles';
import { AuthContext } from '../../../../context/AuthContext';

const MainSidebar = () => {
    const { logout, role } = useContext(AuthContext);
    return (
        <div className="main-sidebar">
            <div className="main-sidebar__logo"></div>
            <SidebarTitle text="МОДУЛЬ ОПИТУВАННЯ" />
            <div className="main-sidebar__container">
                <div className="main-sidebar__items">
                    {role === 'admin' ? (
                        <>
                            <NavLink
                                to="/users"
                                className="main-sidebar__item"
                                activeClassName="main-sidebar__item-active"
                            >
                                <SidebarBtnStyles text="Викладачі" />
                            </NavLink>
                            <NavLink
                                to="/period-report"
                                className="main-sidebar__item"
                                activeClassName="main-sidebar__item-active"
                            >
                                <SidebarBtnStyles text="Звіт за період" />
                            </NavLink>
                        </>
                    ) : (
                        <>
                            <NavLink
                                to="/profile"
                                className="main-sidebar__item"
                                activeClassName="main-sidebar__item-active"
                            >
                                <SidebarBtnStyles text="Профіль" />
                            </NavLink>
                            <NavLink
                                to="/surveys"
                                className="main-sidebar__item"
                                activeClassName="main-sidebar__item-active"
                            >
                                <SidebarBtnStyles text="Опитування" />
                            </NavLink>
                        </>
                    )}
                </div>
                <button className="main-sidebar__exit" onClick={logout}>
                    <SidebarBtnExitStyles text="Вихід" />
                </button>
            </div>
        </div>
    );
};

export default MainSidebar;
