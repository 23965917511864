import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorSnackBar = ({ error }) => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (error !== null) {
            setOpen(true);
        }
    }, [error]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity="error">
                Щось пішло не так, перевітре введені дані
            </Alert>
        </Snackbar>
    );
};

export default ErrorSnackBar;
