import React from 'react';
import Decoration from '../Decoration/Decoration';
import AuthSidebar from './components/AuthSidebar/AuthSidebar';

const WithAuthSidebar = ({ className = '', children = null }) => {
    return (
        <div className={`with-auth-sidebar-page ${className}`}>
            <AuthSidebar />
            <div className="with-auth-sidebar-page__scroll-wrapper">
                <div className="with-auth-sidebar-page__content">
                    <Decoration top />
                    {children}
                    <Decoration bottom />
                </div>
            </div>
        </div>
    );
};

export default WithAuthSidebar;
