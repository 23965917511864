import axios from 'axios';

const registerUser = async (body) => {
    try {
        const data = await axios.post('/api/v1/users/signup', body);
        return data;
    } catch {
        return {
            error: true,
        };
    }
};

const loginUser = async (body) => {
    try {
        const res = await axios.post('/api/v1/users/login', body);
        return res.data;
    } catch (err) {
        return {
            error: true,
        };
    }
};

export { loginUser, registerUser };
