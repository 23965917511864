import fileDownload from 'js-file-download';
import instance from '../../api/axiosApi';
import * as actionTypes from '../actionTypes';

const getExcelReportStart = () => ({
    type: actionTypes.GET_REPORT_EXCEL_START,
});

const getExcelReportSuccess = (survey = {}) => ({
    type: actionTypes.GET_REPORT_EXCEL_SUCCESS,
    payload: survey,
});

const getExcelReportFail = (error) => ({
    type: actionTypes.GET_REPORT_EXCEL_FAIL,
    payload: error,
});

export const getExcelReport = (survey, token) => {
    return async (dispatch) => {
        dispatch(getExcelReportStart());
        try {
            const { programName, group, discipline, user } = survey;

            const res = await instance.get(`/api/v1/surveys/report/${survey._id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fileDownload(
                res.data,
                `${programName}_${discipline}_${group}_${user.lastName}_${user.firstName[0]}_${user.patronymic[0]}.xlsx`,
            );

            dispatch(getExcelReportSuccess());
        } catch (error) {
            dispatch(getExcelReportFail(error));
        }
    };
};

const getChartReportStart = () => ({
    type: actionTypes.GET_CHART_START,
});

const getChartReportSuccess = (data = {}) => ({
    type: actionTypes.GET_CHART_SUCCESS,
    payload: data,
});

const getChartReportFail = (error) => ({
    type: actionTypes.GET_CHART_FAIL,
    payload: error,
});

export const getChartReport = (surveyId, token) => {
    return async (dispatch) => {
        dispatch(getChartReportStart());
        try {
            const { data } = await instance.get(`/api/v1/surveys/chart/${surveyId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            dispatch(getChartReportSuccess(data.data));
        } catch (error) {
            dispatch(getChartReportFail(error));
        }
    };
};

const getPeriodReportParamsStart = () => ({
    type: actionTypes.GET_REPORT_PARAMS_START,
});

const getPeriodReportParamsSuccess = (data = {}) => ({
    type: actionTypes.GET_REPORT_PARAMS_SUCCESS,
    payload: data,
});

const getPeriodReportParamsFail = (error) => ({
    type: actionTypes.GET_REPORT_PARAMS_FAIL,
    payload: error,
});

export const getPeriodReportParams = (token) => {
    return async (dispatch) => {
        dispatch(getPeriodReportParamsStart());
        try {
            const res = await instance.get(
                `/api/v1/surveys/period-report-data`,

                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const data = res.data;

            dispatch(getPeriodReportParamsSuccess(data.data));
        } catch (error) {
            dispatch(getPeriodReportParamsFail(error));
        }
    };
};

const getPeriodReportStart = () => ({
    type: actionTypes.GET_PERIOD_REPORT_START,
});

const getPeriodReportSuccess = () => ({
    type: actionTypes.GET_PERIOD_REPORT_SUCCESS,
});

const getPeriodReportFail = (error) => ({
    type: actionTypes.GET_PERIOD_REPORT_FAIL,
    payload: error,
});

export const getPeriodReport = (data, token) => {
    return async (dispatch) => {
        dispatch(getPeriodReportStart());
        try {
            const res = await instance.post(
                `/api/v1/surveys/period-report?startDate=${data.startDate}&endDate=${data.endDate}&programeName=${data.programName}`,
                data,
                {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            fileDownload(
                res.data,
                `${data.programName ? data.programName.replaceAll(' ', '_') : 'Опитування'}_${
                    data.startDate
                }_${data.endDate}.xlsx`,
            );

            dispatch(getPeriodReportSuccess(data));
        } catch (error) {
            dispatch(getPeriodReportFail(error));
        }
    };
};
