import * as actionTypes from '../actionTypes';

const initialProfileState = {
    lastName: '',
    firstName: '',
    patronimyc: '',
    email: '',
    surveys: [],
};

const initialState = {
    loggedIn: false,
    loading: false,
    error: null,
    profile: { ...initialProfileState },
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_DATA_SUCCESS:
        case actionTypes.GET_USER_SUCCESS:
            return { ...state, loading: false, profile: { ...action.payload }, error: null };
        //
        case actionTypes.GET_PROFILE_DATA_FAIL:
        case actionTypes.UPDATE_PROFILE_DATA_FAIL:
        case actionTypes.UPDATE_USER_FAIL:
        case actionTypes.GET_USER_FAIL:
        case actionTypes.BLOCK_USER_FAIL:
            return { ...state, loading: false, error: action.payload };

        //
        case actionTypes.GET_PROFILE_DATA_START:
        case actionTypes.GET_USER_START:
        case actionTypes.BLOCK_USER_START:
        case actionTypes.UPDATE_PROFILE_DATA_START:
        case actionTypes.UPDATE_USER_START:
            return { ...state, loading: true, error: null };
        //
        case actionTypes.UPDATE_PROFILE_DATA_SUCCESS:
        case actionTypes.UPDATE_USER_SUCCESS:
            return { ...state, loading: false, profile: { ...action.payload }, error: null };

        case actionTypes.BLOCK_USER_SUCCESS:
            return { ...state, loading: false, profile: initialProfileState, error: null };

        default:
            return state;
    }
};
