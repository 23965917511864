import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '21px',
        textAlign: 'center',
        color: '#333333',
    },
}));

const SidebarBtnStyles = ({ text }) => {
    const classes = useStyles();

    return <h3 className={classes.root}>{text}</h3>;
};

const useStylesExit = makeStyles(() => ({
    root: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '21px',
        textAlign: 'center',
        color: '#E74C3C',
    },
}));

const SidebarBtnExitStyles = ({ text }) => {
    const classes = useStylesExit();

    return <h3 className={classes.root}>{text}</h3>;
};

export { SidebarBtnStyles, SidebarBtnExitStyles };
