import React, { useContext, useEffect } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubtitle from '../../components/PageTitle/PageSubtitle';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import './EditingTeacherPage.scss';
import EditingTeacher from '../../components/EditingTeacher/EditingTeacher';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import { getUserData } from '../../store/actions/userActions';
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader';

const EditingTeacherPage = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { profile, error, loading } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getUserData(userId, auth.token));
    }, [dispatch, userId, auth]);
    return (
        <WithMainSidebar noBottomDecoration>
            <div className="container">
                <PageTitle text="Редагування викладача" />
                <PageSubtitle
                    text={`${profile.lastName} ${profile.firstName} ${profile.patronymic}`}
                />
                <PageSubtitle text={`${profile.email}`} />
                <EditingTeacher user={profile} error={error} />
                <BackdropLoader isOpen={loading} />
            </div>
        </WithMainSidebar>
    );
};

export default EditingTeacherPage;
