import * as actionTypes from '../actionTypes';

const initialState = {
    email: '',
    password: '',
    passwordConfirm: '',
};

export const singupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SINGUP_DATA:
            return state;

        case actionTypes.SET_SINGUP_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
