import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        textAlign: 'left',
        lineHeight: '21px',
        color: 'map-get($map: $colors, $key: \'dark-text\')',

        marginTop: '14px',
    },
}));

const PageSubtitle = ({ text }) => {
    const classes = useStyles();

    return <h3 className={classes.root}>{text}</h3>;
};

export default PageSubtitle;
