import instance from '../../api/axiosApi';
import * as actionTypes from '../actionTypes';

const submitPollStart = () => ({
    type: actionTypes.SUBMIT_POLL_START,
});

const submitPollSuccess = (pollData = {}) => ({
    type: actionTypes.SUBMIT_POLL_SUCCESS,
    payload: pollData,
});

const submitPollFail = (error) => ({
    type: actionTypes.SUBMIT_POLL_FAIL,
    payload: error,
});

export const submitPoll = (pollData = {}, survey) => {
    return async (dispatch) => {
        dispatch(submitPollStart());
        try {
            const res = await instance.post('/api/v1/answers', { ...pollData, survey });
            const data = res.data.data.user; // don't ask why

            dispatch(submitPollSuccess(data));
        } catch (error) {
            dispatch(submitPollFail(error));
        }
    };
};
