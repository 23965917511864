import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import './Button.scss';

const useStyles = makeStyles(() => ({
    btn: {
        height: 60,
        fontWeight: 'bold',
    },
}));

// TODO: should be implemented 4 variants of  a button
const BasicButton = ({ text = '', className = '', children, ...btnProps }) => {
    const classes = useStyles();
    return (
        <Button className={clsx(classes.btn, className)} {...btnProps}>
            {text ? text : children}
        </Button>
    );
};

export default BasicButton;
