export const attentionSelectOptions = [
    {
        label: 'Сучасних досягненнях науки і техніки',
        value: '1',
    },
    {
        label: 'Сучасних практиках, які застосовуються у вашій майбутній  професійній сфері',
        value: '2',
    },
    // {
    //     label: 'Інше',
    //     value: '3',
    // },
];

export const reasonSelectOptions = [
    {
        label: 'Практичними кейсами (за фахом)',
        value: '1',
    },
    {
        label: 'Вивченням зарубіжним досвідом',
        value: '2',
    },
    {
        label: 'Використанням сучасного програмного забезпечення',
        value: '3',
    },
    {
        label: 'Нетрадиційними прийомами викладання ',
        value: '4',
    },
    {
        label: 'Діловими іграми',
        value: '5',
    },
    {
        label: 'Командними завданнями',
        value: '6',
    },
    {
        label: 'Проективними методиками',
        value: '7',
    },
    {
        label: 'Розробленням і захистом проекту',
        value: '8',
    },
    {
        label: 'Якісною візуалізацією матеріалу',
        value: '10',
    },
    {
        label: 'Цікавими практичними завданнями',
        value: '9',
    },
    // {
    //     label: 'Інше',
    //     value: '11',
    // },
];

export const lecturesQuantityOptions = [
    {
        label: 'Так, достатньо',
        value: 'Так, достатньо',
    },
    {
        label: 'Лекцій хотілось би більше',
        value: 'Лекцій хотілось би більше',
    },
    {
        label: 'Лекцій можна було менше',
        value: 'Лекцій можна було менше',
    },
];

export const lessonsQuantityOptions = [
    {
        label: 'Так, достатньо',
        value: 'Так, достатньо',
    },
    {
        label: 'Практичних / лабораторних занять не вистачало',
        value: 'Практичних / лабораторних занять не вистачало',
    },
    {
        label: 'Практичних / лабораторних занять було забагато',
        value: 'Практичних / лабораторних занять було забагато',
    },
];

export const literatureProvideOptions = [
    {
        label: 'Конспект лекцій',
        value: 'Конспект',
    },
    {
        label: 'Методичні рекомендації до вивчення дисципліни',
        value: 'Методичні рекомендації до вивчення дисципліни',
    },
    {
        label: 'Методичні рекомендації до виконання лабораторних робіт',
        value: 'Методичні рекомендації до виконання лабораторних робіт',
    },
    {
        label: 'Методичні рекомендації до виконання практичних занять',
        value: 'Методичні рекомендації до виконання практичних занять',
    },
    {
        label: 'Методичні рекомендації до виконання курсової роботи/ курсового проекту',
        value: 'Методичні рекомендації до виконання курсової роботи/ курсового проекту',
    },
    // {
    //     label: 'Інше',
    //     value: 'Інше',
    // },
];

export const literatureNeedOptions = [
    {
        label: 'Так',
        value: 'Так',
    },
    {
        label: 'Ні',
        value: 'Ні',
    },
    {
        label: 'Важко відповісти',
        value: 'Важко відповісти',
    },
];

export const isDelayOptions = [
    {
        label: 'Так',
        value: 'Так',
    },
    {
        label: 'Ні',
        value: 'Ні',
    },
    {
        label: 'Потребують уточнення, конкретизації',
        value: 'Потребують уточнення, конкретизації',
    },
];
