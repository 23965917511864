import { useCallback, useState, useEffect } from 'react';
import { secretLink } from './secretLink';

export const storageName = 'userData';

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [role, serRole] = useState(null);
    const [ready, setReady] = useState(false);

    const login = useCallback((jwtToken, id, role) => {
        setToken(jwtToken);
        setUserId(id);
        serRole(role);
        const storageValue = JSON.stringify({ userId: id, token: jwtToken, role });
        localStorage.setItem(storageName, storageValue);
    }, []);
    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        serRole(null);
        document.location = `/${secretLink}/login`;
        localStorage.setItem(storageName, '');
    }, []);

    useEffect(() => {
        const storage = localStorage.getItem(storageName);

        const data = JSON.parse(storage || '{}');
        if (data && data.token) {
            login(data.token, data.userId, data.role);
        }
        setReady(true);
    }, [login]);

    return { login, logout, ready, role, token, userId };
};

export const actionLogout = () => {
    localStorage.setItem(storageName, '');
    sessionStorage.setItem(storageName, '');
    document.location = `/${secretLink}/login`;
};
