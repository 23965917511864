import instance from '../../api/axiosApi';
import { actionLogout } from '../../hooks/auth.hook';
import * as actionTypes from '../actionTypes';
import { getAllUsers } from './usersActions';

const getProfileDataStart = () => ({
    type: actionTypes.GET_PROFILE_DATA_START,
});

const getProfileDataSuccess = (profileData = {}) => ({
    type: actionTypes.GET_PROFILE_DATA_SUCCESS,
    payload: profileData,
});

const getProfileDataFail = (error) => ({
    type: actionTypes.GET_PROFILE_DATA_FAIL,
    payload: error,
});

export const getProfileData = (token) => {
    return async (dispatch) => {
        dispatch(getProfileDataStart());
        try {
            const res = await instance.get('/api/v1/users/me', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.user.user; // don't ask why

            dispatch(getProfileDataSuccess(data));
        } catch (error) {
            dispatch(getProfileDataFail(error));
        }
    };
};

const updateProfileDataStart = () => ({
    type: actionTypes.UPDATE_PROFILE_DATA_START,
});

const updateProfileDataSuccess = (profileData = {}) => ({
    type: actionTypes.UPDATE_PROFILE_DATA_SUCCESS,
    payload: profileData,
});

const updateProfileDataFail = (error) => ({
    type: actionTypes.UPDATE_PROFILE_DATA_FAIL,
    payload: error,
});

export const updateProfileData = (profileData = {}, token) => {
    return async (dispatch) => {
        dispatch(updateProfileDataStart());
        try {
            const res = await instance.patch('/api/v1/users/updateMe', profileData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.user; // don't ask why
            if (data.password) {
                actionLogout();
            }
            dispatch(updateProfileDataSuccess(data));
        } catch (error) {
            dispatch(updateProfileDataFail(error));
        }
    };
};

const blockUserStart = () => ({
    type: actionTypes.BLOCK_USER_START,
});

const blockUserSuccess = (data = {}) => ({
    type: actionTypes.BLOCK_USER_SUCCESS,
    payload: data,
});

const blockUserFail = (error) => ({
    type: actionTypes.BLOCK_USER_FAIL,
    payload: error,
});

export const blockUser = (id, token) => {
    return async (dispatch) => {
        dispatch(blockUserStart());
        try {
            await instance.post(
                `/api/v1/users/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            dispatch(blockUserSuccess());
            dispatch(getAllUsers(token));
        } catch (error) {
            dispatch(blockUserFail(error));
        }
    };
};

const updateUserDataStart = () => ({
    type: actionTypes.UPDATE_USER_START,
});

const updateUserDataSuccess = (data = {}) => ({
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: data,
});

const updateUserDataFail = (error) => ({
    type: actionTypes.UPDATE_USER_FAIL,
    payload: error,
});

export const updateUserData = (userData = {}, id, token) => {
    return async (dispatch) => {
        dispatch(updateUserDataStart());
        try {
            const res = await instance.patch(`/api/v1/users/${id}`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = res.data.data.user; // don't ask why
            dispatch(updateUserDataSuccess(data));
        } catch (error) {
            dispatch(updateUserDataFail(error));
        }
    };
};

const getUserDataStart = () => ({
    type: actionTypes.GET_USER_START,
});

const getUserDataSuccess = (profileData = {}) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: profileData,
});

const getUserDataFail = (error) => ({
    type: actionTypes.GET_USER_FAIL,
    payload: error,
});

export const getUserData = (id, token) => {
    return async (dispatch) => {
        dispatch(getUserDataStart());
        try {
            const res = await instance.get(`/api/v1/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const { user, surveys } = res.data.data.user; // don't ask why
            const data = { ...user, surveys: surveys };
            dispatch(getUserDataSuccess(data));
        } catch (error) {
            dispatch(getUserDataFail(error));
        }
    };
};
