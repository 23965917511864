import React, { useContext, useEffect } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import BasicButton from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getPeriodReport, getPeriodReportParams } from '../../store/actions/reportActions';
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import './PeriodReportPage.scss';
// validationSchema

const initialValues = {
    startDate: '',
    endDate: '',
    programName: '',
};

const validationSchema = yup.object({
    startDate: yup.string().required(`Поле є обов'язковим`),
    endDate: yup.string().required(`Поле є обов'язковим`),
    programName: yup.string(),
});

const PeriodReportPage = () => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { periodReportParams, loading } = useSelector((state) => state.report);
    const [selectValue, setSelectValue] = React.useState('');
    useEffect(() => {
        dispatch(getPeriodReportParams(auth.token));
    }, []);
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            dispatch(getPeriodReport(values, auth.token));
            resetForm(initialValues);
        },
    });

    return (
        <WithMainSidebar noBottomDecoration>
            <BackdropLoader isOpen={loading} />

            <div className="period-report container">
                <PageTitle text="Звіт за період" />

                <form className="period-report__form" onSubmit={formik.handleSubmit}>
                    <Autocomplete
                        value={selectValue}
                        onChange={(event, newValue) => {
                            setSelectValue(newValue);
                        }}
                        inputValue={formik.values.programName}
                        onInputChange={(e, value) => formik.setFieldValue('programName', value)}
                        options={['', ...periodReportParams.programNames]}
                        getOptionLabel={(option) => option}
                        style={{ width: 500 }}
                        name="programName"
                        className="period-report__input"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="НАЗВА ОСВІТНЬО-ПРОФЕСІЙНОЇ ПРОГРАМИ (РІК)"
                                variant="outlined"
                                name="programName"
                            />
                        )}
                    />
                    <div className="period-report__date-wrapper">
                        <TextField
                            name="startDate"
                            label="З"
                            type="date"
                            className="period-report__input"
                            onChange={formik.handleChange}
                            value={formik.values.startDate}
                            InputProps={{
                                inputProps: {
                                    min: moment(periodReportParams.minDate).format('YYYY-MM-DD'),
                                    max: formik.values.endDate
                                        ? formik.values.endDate
                                        : moment(periodReportParams.maxDate).format('YYYY-MM-DD'),
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={formik.errors.startDate && formik.touched.startDate}
                            helperText={formik.errors.startDate}
                        />
                        <TextField
                            name="endDate"
                            label="По"
                            type="date"
                            className="period-report__input"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            InputProps={{
                                inputProps: {
                                    min: formik.values.startDate
                                        ? formik.values.startDate
                                        : moment(periodReportParams.minDate).format('YYYY-MM-DD'),
                                    max: moment(periodReportParams.maxDate).format('YYYY-MM-DD'),
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={formik.errors.endDate && formik.touched.endDate}
                            helperText={formik.errors.endDate}
                        />
                    </div>
                    <BasicButton
                        disabled={!formik.isValid}
                        type="submit"
                        color="primary"
                        variant="contained"
                        className="period-report__smt-btn"
                        startIcon={<GetAppIcon />}
                    >
                        Завантажити
                    </BasicButton>
                </form>
            </div>
        </WithMainSidebar>
    );
};

export default PeriodReportPage;
