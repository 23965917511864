import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const Loader = () => {
    return (
        <Box
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignContent="center"
        >
            <CircularProgress color="secondary" />
        </Box>
    );
};

export default Loader;
