import { createMuiTheme } from '@material-ui/core';

const PRIMARY_COLOR = '#2D9CDB';
const SECONDARY_COLOR = '#27AE60';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
    },
    mixins: {},
});
