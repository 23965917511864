import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { useAuth } from './hooks/auth.hook';
import { useRoutes } from './Routes/Routes';
import { Provider } from 'react-redux';
import store from './store/store';
import './scss/main.scss';
import { theme } from './config/theme';
function App() {
    const { token, login, logout, userId, role } = useAuth();
    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated, role);
    document.title = 'НУХТ | ОПИТУВАННЯ';
    return (
        <Provider store={store}>
            <AuthContext.Provider value={{ token, login, role, logout, userId, isAuthenticated }}>
                <StylesProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Router>{routes}</Router>
                    </ThemeProvider>
                </StylesProvider>
            </AuthContext.Provider>
        </Provider>
    );
}

export default App;
