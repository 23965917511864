export const TEACHER_TABLE_HEAD_DATA = {
    teacher: 'Викладач',
    email: 'Електронна пошта',
};
export const ACTIVE_TABLE_HEAD_DATA = {
    dateCreate: 'Дата створення',
    program: 'Назва освітньо-професійної програми (Рік)',
    discipline: 'Дисципліна',
    group: 'Група',
};
export const DISABLE_TABLE_HEAD_DATA = {
    dateCreate: 'Дата створення',
    dateDisable: 'Дата завершення',
    program: 'Назва освітньо-професійної програми (Рік)',
    discipline: 'Дисципліна',
    group: 'Група',
};

export const FINISHED_TABLE_HEAD_DATA = {
    dateCreate: 'Дата створення',
    dateDisable: 'Дата завершення',
    program: 'Назва освітньо-професійної програми (Рік)',
    discipline: 'Дисципліна',
    group: 'Група',
    result: 'Результат',
    edit: 'Керувати',
};
