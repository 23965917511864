import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import ConfimSignUp from '../pages/ConfimSignUp/ConfimSignUp';
// import FirstPage from '../pages/FirstPage/FirstPage';
// import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
// import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Login from '../pages/Login/Login';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import PublicUserPage from '../pages/PublicUserPage/PublicUserPage';
import SignUp from '../pages/SignUp/SignUp';
import CreateSurveyPage from '../pages/CreateSurveyPage/CreateSurveyPage';
import EditingTeacherPage from '../pages/EditingTeacherPage/EditingTeacherPage';
import MySuryves from '../pages/MySuryves/MySuryves';
import AdminTeachersPage from '../pages/AdminTeachers/AdminTeachersPage';
import AdminUserSurveysPage from '../pages/AdminUserSurveysPage/AdminUserSurveysPage';
import NotFound from '../pages/NotFound';
import { secretLink } from '../hooks/secretLink';
import ChartPage from '../pages/ChartPage/ChartPage';
import PeriodReportPage from '../pages/PeriodReportPage/PeriodReportPage';

export const useRoutes = (isAuthenticated, role) => {
    if (isAuthenticated) {
        if (role === 'admin') {
            return (
                <Switch>
                    <Route path="/users/:userId">
                        <AdminUserSurveysPage />
                    </Route>
                    <Route path="/edit/:userId">
                        <EditingTeacherPage />
                    </Route>
                    <Route path="/users" exact>
                        <AdminTeachersPage />
                    </Route>
                    <Route path="/charts/:surveyId">
                        <ChartPage />
                    </Route>
                    <Route path="/period-report" exact>
                        <PeriodReportPage />
                    </Route>
                    <Redirect to="/users" />
                </Switch>
            );
        } else if (role === 'user') {
            return (
                <Switch>
                    <Route path="/profile" exact>
                        <ProfilePage />
                    </Route>
                    <Route path="/create-survey" exact>
                        <CreateSurveyPage />
                    </Route>
                    <Route path="/surveys" exact>
                        <MySuryves />
                    </Route>
                    <Redirect to="/surveys" />
                </Switch>
            );
        }
    }

    return (
        <Switch>
            <Route path="/survey/:surveyId">
                <PublicUserPage />
            </Route>
            <Route path={`/${secretLink}/singup`} exact>
                <SignUp />
            </Route>
            <Route path={`/${secretLink}/login`} exact>
                <Login />
            </Route>
            {/* TODO: */}
            {/* <Route path="/singup/confirm" exact>
                <ConfimSignUp />
            </Route>
            <Route path="/login/forgotPassword" exact>
                <ForgotPassword />
            </Route>
            <Route path="/login/resetPassword">
                <ResetPassword />
            </Route> */}

            <Route path="/">
                <NotFound />
            </Route>
            <Redirect to="/" />
        </Switch>
    );
};
