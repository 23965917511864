import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import BasicInput from '../../components/BasicInput/BasicInput';
import BasicButton from '../Button/Button';
import ErrorSnackBar from '../ErrorSnackBar/ErrorSnackBar';
import { AuthContext } from '../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../store/actions/userActions';

const useStyles = makeStyles(() => ({
    submitBtn: {
        marginTop: 40,
    },
}));

const validationSchema = yup.object({
    email: yup.string('Введіть електронну пошту').email('Введіть валідну електронну пошту'),
    newPassword: yup
        .string('Введіть новий пароль')
        .min(8, 'Пароль повинен містити не менше 8 символів'),
    newPasswordConfirm: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Паролі повинні збігатися'),
    password: yup
        .string('Для збереження змін введіть поточний пароль адміністратора')
        .required(`Пароль адміністратора є обовя'зковим`),
});

const initialValues = {
    email: '',
    newPassword: '',
    newPasswordConfirm: '',
    password: '',
};

const EditingTeacher = ({ user, error }) => {
    const classes = useStyles();
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();

    const updateUser = useCallback(
        (data, id) => {
            dispatch(updateUserData(data, id, auth.token));
        },
        [auth.token, dispatch],
    );
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            updateUser(values, user._id);
            resetForm(initialValues);
        },
    });

    const errorsMessage = Object.keys(formik.errors).map((field) => {
        if (formik.touched[field]) {
            return formik.errors[field];
        }
        return '';
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <ErrorSnackBar error={error} />

            <BasicInput
                placeholder="Залиште без змін, якщо не бажаєте змінювати"
                noAutocomplete
                type="text"
                name="email"
                label="Нова електронна пошта"
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <BasicInput
                type="password"
                name="newPassword"
                label="Новий пароль"
                margin="normal"
                placeholder="Залиште без змін, якщо не бажаєте змінювати пароль"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            />
            <BasicInput
                name="newPasswordConfirm"
                label="Повторіть новий пароль"
                placeholder="Залиште без змін, якщо не бажаєте змінювати пароль"
                type="password"
                margin="dense"
                value={formik.values.newPasswordConfirm}
                onChange={formik.handleChange}
                error={
                    formik.touched.newPasswordConfirm && Boolean(formik.errors.newPasswordConfirm)
                }
            />
            <BasicInput
                name="password"
                label="Для збереження змін введіть пароль адміністратора"
                type="password"
                margin="dense"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
            />
            <p className="error-field">{errorsMessage[0]}</p>

            <BasicButton
                color="secondary"
                className={(classes.submitBtn, 'EditingTeacher__btn')}
                style={{ marginTop: '20px' }}
                variant="contained"
                fullWidth
                type="submit"
            >
                Зберегти
            </BasicButton>
            <BasicButton color="primary" variant="outlined" className={classes.submitBtn} fullWidth>
                Повернутись до викладачів
            </BasicButton>
        </form>
    );
};

export default EditingTeacher;
