import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubtitle from '../../components/PageTitle/PageSubtitle';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import CreateSurveyForm from '../../components/CreateSurveyForm/CreateSurveyForm';
import SuccessCreated from '../../components/SuccessCreated/SuccessCreated';
import { useDispatch, useSelector } from 'react-redux';
import { surveyReset } from '../../store/actions/surveyAction';

const CreateSurveyPage = () => {
    const { survey } = useSelector((state) => state.survey);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(surveyReset());
    }, [dispatch]);
    return (
        <WithMainSidebar noBottomDecoration>
            {survey._id ? (
                <SuccessCreated />
            ) : (
                <div className="container">
                    <PageTitle text="Створення опитування" />
                    <PageSubtitle text="Назва освітньо-професійної програми (рік)" />
                    <CreateSurveyForm />
                </div>
            )}
        </WithMainSidebar>
    );
};

export default CreateSurveyPage;
