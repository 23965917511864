import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px',
        textAlign: 'right',
        lineHeight: '42px',
        paddingRight: '10px',
        color: '#FFFFFF',
    },
}));

const SidebarTitle = ({ text }) => {
    const classes = useStyles();

    return <h2 className={classes.root}>{text}</h2>;
};

export default SidebarTitle;
