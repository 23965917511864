import React, { useContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from '../../components/LinkButton/LinkButton';
import { ACTIVE_TABLE_HEAD_DATA, DISABLE_TABLE_HEAD_DATA } from '../../components/Table/headers';
import FullTable from '../../components/Table/Table';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import { AuthContext } from '../../context/AuthContext';
import { getActiveSurveys, getArchiveSurvey } from '../../store/actions/surveyAction';
import AddBoxIcon from '@material-ui/icons/AddBox';
const pageCounter = (length, limit) => {
    return Math.ceil(length / limit);
};

const MySuryves = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const { activeSurveys, archiveSurveys } = useSelector((state) => state.survey);
    const [activeSurveysPage, setActiveSurveysPage] = useState(1);
    const [archiveSurveysPage, setArchiveSurveysPage] = useState(1);

    const getUserSurveys = useCallback(() => {
        dispatch(getActiveSurveys(auth.token));
        dispatch(getArchiveSurvey(auth.token));
    }, [dispatch, auth.token]);
    useEffect(() => {
        getUserSurveys();
    }, [getUserSurveys, activeSurveysPage, archiveSurveysPage]);

    const handleChangeActivePage = (e, page) => {
        setActiveSurveysPage(page);
    };
    const handleChangeArchivePage = (e, page) => {
        setArchiveSurveysPage(page);
    };

    return (
        <WithMainSidebar noBottomDecoration>
            <div className="my-survey">
                <div className="my-survey__header">
                    <h2 className="my-survey__title">Опитування</h2>
                    <LinkButton href="/create-survey" color="primary" variant="contained">
                        <AddBoxIcon/>
                        Створити
                    </LinkButton>
                </div>
                <h2 className="my-survey__subtitle">Активні опитування</h2>
                <FullTable
                    handleChange={handleChangeActivePage}
                    page={activeSurveysPage}
                    limitShowRow={5}
                    countPage={pageCounter(activeSurveys.length, 5)}
                    fields={activeSurveys}
                    headers={ACTIVE_TABLE_HEAD_DATA}
                    typeTable="activeHub"
                />
                <h2 className="my-survey__subtitle">Завершені опитування</h2>
                <FullTable
                    limitShowRow={5}
                    handleChange={handleChangeArchivePage}
                    page={archiveSurveysPage}
                    fields={archiveSurveys}
                    countPage={pageCounter(archiveSurveys.length, 5)}
                    headers={DISABLE_TABLE_HEAD_DATA}
                    typeTable="disableHub"
                />
            </div>
        </WithMainSidebar>
    );
};

export default MySuryves;
