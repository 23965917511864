import React from 'react';

const AuthSidebar = () => {
    return (
        <div className="auth-sidebar">
            <div className="auth-sidebar__logo"></div>
        </div>
    );
};

export default AuthSidebar;
