import * as actionTypes from '../actionTypes';

const initialState = {
    loggedIn: false,
    loading: false,
    error: null,
    survey: { _id: '' },
    activeSurveys: [],
    archiveSurveys: [],
};

export const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_SURVEY_START:
        case actionTypes.GET_SURVEY_START:
        case actionTypes.GET_ACTIVE_SURVEY_START:
        case actionTypes.GET_ARCHIVE_SURVEY_START:
        case actionTypes.RESTART_SURVEY_START:
        case actionTypes.DELETE_SURVEY_START:
            return { ...state, loading: true, error: null };

        case actionTypes.GET_SURVEY_FAIL:
        case actionTypes.CREATE_SURVEY_FAIL:
        case actionTypes.GET_ACTIVE_SURVEY_FAIL:
        case actionTypes.GET_ARCHIVE_SURVEY_FAIL:
        case actionTypes.RESTART_SURVEY_FAIL:
        case actionTypes.DELETE_SURVEY_FAIL:
            return { ...state, loading: false, error: action.payload };

        case actionTypes.CREATE_SURVEY_SUCCESS:
        case actionTypes.GET_SURVEY_SUCCESS:
        case actionTypes.RESTART_SURVEY_SUCCESS:
        case actionTypes.DELETE_SURVEY_SUCCESS:
            return { ...state, loading: false, survey: { ...action.payload }, error: null };
        case actionTypes.GET_ACTIVE_SURVEY_SUCCESS:
            return { ...state, loading: false, activeSurveys: action.payload, error: null };
        case actionTypes.GET_ARCHIVE_SURVEY_SUCCESS:
            return { ...state, loading: false, archiveSurveys: action.payload, error: null };

        case actionTypes.SURVEY_RESER:
            return initialState;

        default:
            return state;
    }
};
