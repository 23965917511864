import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

const BackdropLoader = ({ className, isOpen }) => {
    return (
        <div>
            <Backdrop style={{ zIndex: 9999 }} className={className} open={isOpen}>
                <CircularProgress />
            </Backdrop>
        </div>
    );
};

export default BackdropLoader;
