import React from 'react';

const Decoration = ({ className, top = false, bottom = false }) => {
    return (
        <div
            className={`decoration ${className} ${top && 'decoration--top'} ${
                bottom && 'decoration--bottom'
            }`}
            // FIXME: classNames !!!!
        >
            <div className="decoration__dot-wrapper">
                <div className="decoration__dot decoration__dot--blue"></div>
                <div className="decoration__dot decoration__dot--green"></div>
                <div className="decoration__dot decoration__dot--grey"></div>
            </div>
        </div>
    );
};

export default Decoration;
