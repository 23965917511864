import React, { useState } from 'react';
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';

const useStyles = makeStyles({
    label: {
        fontSize: 16,
    },
    root: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        width: '100%',

        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
    },
});

const RateField = ({ label, name, setFieldValue }) => {
    const scale = [1, 2, 3, 4, 5];
    const classes = useStyles();
    const [value, setValue] = useState(null);

    const handleRadioChange = (event) => {
        setValue(Number(event.currentTarget.value));
        setFieldValue(name, Number(event.currentTarget.value));
    };

    return (
        <FormControl fullWidth component="fieldset">
            <h2 className={classes.label}>{label}</h2>
            <RadioGroup
                row
                aria-label="linear-scale"
                className={classes.root}
                name={name}
                value={value}
                onChange={handleRadioChange}
            >
                {scale.map((item) => (
                    <FormControlLabel
                        key={item}
                        value={item}
                        control={<Radio />}
                        classsName={classes.label}
                        label={item}
                        labelPlacement="bottom"
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RateField;
