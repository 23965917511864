import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { registerUser } from '../../api/auth';
import BasicInput from '../../components/BasicInput/BasicInput';
import BasicButton from '../../components/Button/Button';
import LinkButton from '../../components/LinkButton/LinkButton';
import WithAuthSidebar from '../../components/WithAuthSidebar/WithAuthSidebar';
import { AuthContext } from '../../context/AuthContext';
import { secretLink } from '../../hooks/secretLink';

const validationSchema = yup.object({
    firstName: yup
        .string(`Введіть ваше і'мя`)
        .min(2, `Мінімальна і'мя довжина 2 символи`)
        .max(180, 'Максимальна довжина 180 символів')
        .required(`І'мя є обовя'зковим`),
    lastName: yup
        .string('Введіть прізвище')
        .min(2, 'Мінімальна прізвища довжина 2 символи')
        .max(180, 'Максимальна прізвища довжина 180 символів')
        .required(`Прізвище є обовя'зковим`),
    patronymic: yup
        .string('Введіть По-батькові')
        .min(2, 'Мінімальна По-батькові довжина 2 символи')
        .max(180, 'Максимальна По-батькові довжина 180 символів')
        .required(`По-батькові є обовя'зковим`),
    email: yup
        .string('Введіть електронну пошту')
        .email('Введіть валідну електронну пошту')
        .required(`Електронна пошта є обовя'зковою`),
    password: yup
        .string('Введіть пароль')
        .min(8, 'Пароль повинен містити не менше 8 символів')
        .required(`Пароль є обовя'зковим`),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Паролі повинні збігатися'),
});

const SignUp = () => {
    const [error, setError] = useState('');
    const auth = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            patronymic: '',
            email: '',
            password: '',
            passwordConfirm: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setError('');
            const res = await registerUser(values);
            if (!res || res.error)
                return setError('Невірний дані або ця пошта вже використовується');
            auth.login(res.data.token, res.data.data.user._id, res.data.data.user.role);
        },
    });

    const errorsMessage = Object.keys(formik.errors).map((field) => {
        if (formik.touched[field]) {
            return formik.errors[field];
        }
        return '';
    });
    errorsMessage.push(error);

    return (
        <WithAuthSidebar>
            <div className="singup-page">
                <h2 className="singup-page__title title">РЕЄСТРАЦІЯ</h2>
                <div className="singup-page__form">
                    <form onSubmit={formik.handleSubmit}>
                        <BasicInput
                            name="lastName"
                            label="Прізвище"
                            margin="normal"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                        />
                        <BasicInput
                            name="firstName"
                            label="І'мя"
                            margin="normal"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                        />
                        <BasicInput
                            name="patronymic"
                            label="По-батькові"
                            margin="normal"
                            value={formik.values.patronymic}
                            onChange={formik.handleChange}
                        />
                        <BasicInput
                            type="email"
                            name="email"
                            label="Електронна пошта"
                            margin="normal"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                        <BasicInput
                            name="password"
                            label="Пароль"
                            type="password"
                            margin="dense"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        <BasicInput
                            name="passwordConfirm"
                            label="Повторіть пароль"
                            type="password"
                            margin="dense"
                            value={formik.values.passwordConfirm}
                            onChange={formik.handleChange}
                        />

                        <p className="error-field">{errorsMessage[0]}</p>
                        <BasicButton
                            color="primary"
                            fullWidth
                            className="login"
                            variant="contained"
                            type="submit"
                        >
                            ЗАРЕЄСТРУВАТИСЬ
                        </BasicButton>
                    </form>
                </div>

                <div className="login__page--singup-wrapper">
                    <p className="singup-page__login-text">або якщо Ви вже зареєстровані</p>
                    <LinkButton
                        color="secondary"
                        fullWidth
                        variant="outlined"
                        href={`/${secretLink}/login`}
                        className="singup-page__login-link"
                    >
                        ВХІД
                    </LinkButton>
                </div>
            </div>
        </WithAuthSidebar>
    );
};

export default SignUp;
