import { makeStyles } from '@material-ui/core';
import React from 'react';
import Logo from '../assets/icons/logo.png';

const useStyles = makeStyles({
    wrapper: {
        width: '100wh',
        height: '100vh',
        background: 'linear-gradient(180deg, #27ae60 0%, #2d9cdb 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    titles: {
        color: '#fff',
        marginBottom: '30px',
    },
    img: {
        width: '300px',
    },
});

const NotFound = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <h1 className={classes.titles}>404 Сторінка не знайдена</h1>
            <img className={classes.img} src={Logo} alt="" />
        </div>
    );
};

export default NotFound;
