import instance from '../../api/axiosApi';
import * as actionTypes from '../actionTypes';

const getAllUsersStart = () => ({
    type: actionTypes.GET_ALL_USERS_START,
});

const getAllUsersSuccess = (data = {}) => ({
    type: actionTypes.GET_ALL_USERS_SUCCESS,
    payload: data,
});

const getAllUsersFail = (error) => ({
    type: actionTypes.GET_ALL_USERS_FAIL,
    payload: error,
});

export const getAllUsers = (token) => {
    return async (dispatch) => {
        dispatch(getAllUsersStart());
        try {
            const res = await instance.get('/api/v1/users', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = res.data.data.users; // don't ask why
            dispatch(getAllUsersSuccess(data));
        } catch (error) {
            dispatch(getAllUsersFail(error));
        }
    };
};
