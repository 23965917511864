import React, { useState } from 'react';
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import './RadioGroup.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '8px',
    },
    label: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 16,
    },
}));

const CustomRadioGroup = ({ label, name, options, setFieldValue }) => {
    const classes = useStyles();
    const [value, setValue] = useState(null);

    const handleRadioChange = (event) => {
        setValue(event.currentTarget.value);
        setFieldValue(name, event.currentTarget.value);
    };

    return (
        <FormControl component="fieldset" className={classes.root}>
            <h2 className={classes.label}>{label}</h2>
            <RadioGroup
                row
                aria-label="linear-scale"
                className={classes.root}
                name={name}
                value={value}
                onChange={handleRadioChange}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={Math.random()}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default React.memo(CustomRadioGroup);
