import React from 'react';
import { Dialog, DialogActions, DialogTitle, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import BasicButton from '../Button/Button';
import './ConfirationDialog.scss';

const useStyles = makeStyles(() => ({
    paper: {
        padding: 30,
    },
    title: {
        textTransform: 'uppercase',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',

        '& button': {
            width: '100%',

            '&:first-child': {
                marginRight: 40,
            },
        },
    },
}));

const ConfirmationDialog = ({ open, title, onClose, onConfirm }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ className: clsx(classes.paper) }}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogActions className={classes.actions}>
                <BasicButton onClick={onClose} color="secondary" variant="contained">
                    НІ
                </BasicButton>
                <BasicButton color="primary" variant="contained" onClick={onConfirm}>
                    ТАК
                </BasicButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
