import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-google-charts';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { getChartReport } from '../../store/actions/reportActions';
import './ChartPage.scss';

const ChartPage = () => {
    const auth = useContext(AuthContext);
    const { surveyId } = useParams();

    const dispatch = useDispatch();
    const { chartData } = useSelector((state) => state.report);

    const setTitle = useCallback(() => {
        console.log('chartData', chartData);
        const survey = chartData?.survey;
        const { programName, group, discipline, user } = survey;

        document.title = `${programName}_${discipline}_${group}_${user?.lastName}_${user?.firstName}_${user?.patronymic}`;
    }, [chartData]);

    useEffect(() => {
        setTitle();
    }, [chartData]);

    useEffect(() => {
        dispatch(getChartReport(surveyId, auth.token));
    }, []);

    return (
        <div className="chart-page">
            <h1 className="chart-page__title">Результат опитування</h1>
            <div className="chart-page__first-block">
                <div className="chart-page__label-wraper">
                    <span className="field-label">Освітня програма:</span>
                    <span className="field-name">{chartData?.survey?.programName}</span>
                </div>
                <div className="chart-page__label-wraper">
                    <span className="field-label">Дисципліна (освітня компонента):</span>
                    <span className="field-name">{chartData?.survey?.discipline}</span>
                </div>
                <div className="chart-page__label-wraper">
                    <span className="field-label">Викладач:</span>
                    <span className="field-name">{`${chartData?.survey.user?.lastName} ${chartData?.survey.user?.firstName} ${chartData?.survey.user?.patronymic}`}</span>
                </div>
                {chartData.data.map((el, i) => {
                    if (i === 0) {
                        return (
                            <div className="chart-page__chart-wrapper">
                                <Chart
                                    key={el.key + i}
                                    width={'500px'}
                                    height={'500px'}
                                    chartType="PieChart"
                                    loader={<div>{''}</div>}
                                    data={[['Task', 'Відповіді'], ...el.data]}
                                    options={{
                                        title: `${el.label} (Відповідей: ${el.sum})`,
                                        chartArea: { width: '75%' },
                                    }}
                                />
                            </div>
                        );
                    }
                })}
            </div>
            {chartData.data.map((el, i) => {
                if (el.key === 'toChangeSuggestions' && i > 0) {
                    return (
                        <div className="chart-page__chart-wrapper chart-page__answer-wrapper">
                            <h4 className="chart-page__answer-title">{`${el.label} (Відповідей: ${el.sum})`}</h4>
                            {el.data.map((el, i) => (
                                <p className="chart-page__answer-text">{`${i + 1}. ${el[0]}`}</p>
                            ))}
                        </div>
                    );
                }
                if (i > 0) {
                    return (
                        <div className="chart-page__chart-wrapper">
                            <Chart
                                key={el.key + i}
                                width={'500px'}
                                height={'500px'}
                                chartType="PieChart"
                                loader={<div>{''}</div>}
                                data={[['Task', 'Відповіді'], ...el.data]}
                                options={{
                                    title: `${el.label} (Відповідей: ${el.sum})`,
                                    chartArea: { width: '75%' },
                                }}
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default ChartPage;
