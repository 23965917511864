import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import WithMainSidebar from '../../components/WithMainSidebar/WithMainSidebar';
import './ProfilePage.scss';

const ProfilePage = () => {
    return (
        <WithMainSidebar noBottomDecoration>
            <div className="container">
                <PageTitle text="Профіль користувача" />
                <ProfileForm />
            </div>
        </WithMainSidebar>
    );
};

export default ProfilePage;
