import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import './CheckboxGroup.scss';
import BasicInput from '../../../../../components/BasicInput/BasicInput';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '8px',
        width: '100%',
    },
    label: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 16,
    },
}));

const CheckboxGroup = ({
    label,
    options,
    name,
    setFieldValue,
    values,

    handleBlur,
    onChange,
    inputName,
    inputValue,
}) => {
    const classes = useStyles();

    const handleChange = (event) => {
        if (inputValue.length !== 0) {
            setFieldValue(inputName, '');
        }

        if (values.includes(event.target.value)) {
            const newValuses = values.filter((el) => el !== event.target.value);
            setFieldValue(name, newValuses);
        } else {
            setFieldValue(name, [...values, event.target.value]);
        }
    };

    const handleChangeInput = (event) => {
        if (values.length !== 0) {
            setFieldValue(name, []);
        }

        onChange(event);
    };

    return (
        <FormControl component="fieldset" className={classes.root}>
            <h2 className={classes.label}>{label}</h2>
            <FormGroup name={name} onChange={handleChange}>
                {options.map((option, i) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.includes(option.label)}
                                value={option.label}
                            />
                        }
                        key={i}
                        label={option.label}
                    />
                ))}
            </FormGroup>
            <BasicInput
                type="text"
                label="Інше"
                margin="normal"
                name={inputName}
                value={inputValue}
                onChange={handleChangeInput}
                onBlur={handleBlur}
            />
        </FormControl>
    );
};

export default React.memo(CheckboxGroup);
