import * as actionTypes from '../actionTypes';

const initialState = {
    loggedIn: false,
    loading: false,
    error: null,
    users: [],
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USERS_START:
            return { ...state, loading: true, error: null };
        case actionTypes.GET_ALL_USERS_SUCCESS:
            return { ...state, loading: false, users: action.payload, error: null };
        case actionTypes.GET_ALL_USERS_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};
