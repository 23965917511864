import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import BasicButton from '../Button/Button';
import BasicInput from '../BasicInput/BasicInput';
import { useDispatch, useSelector } from 'react-redux';
import { createSyrvey } from '../../store/actions/surveyAction';
import { AuthContext } from '../../context/AuthContext';
import LinkButton from '../LinkButton/LinkButton';
import Loader from '../Loader/Loader';

const useStyles = makeStyles(() => ({
    submitBtn: {
        marginTop: 40,
    },
}));

const validationSchema = yup.object({
    programName: yup
        .string('Оберіть програму')
        .required(`Оберіть програму`)
        .max(200, 'Довжина поля програми перевищує 200 символів'),
    discipline: yup
        .string('Оберіть дисципніну')
        .required(`Оберіть дисципніну`)
        .max(200, 'Довжина поля дисципніна перевищує 200 символів'),
    group: yup
        .string('Оберіть групу')
        .required(`Оберіть групу`)
        .max(200, 'Довжина поля група перевищує 200 символів'),
});

const CreateSurveyForm = () => {
    const classes = useStyles();
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state) => state.survey);
    const formik = useFormik({
        initialValues: {
            programName: '',
            discipline: '',
            group: '',
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(createSyrvey(values, auth.token));
        },
    });

    const errorsMessage = Object.keys(formik.errors).map((field) => {
        if (formik.touched[field]) {
            return formik.errors[field];
        }
        return '';
    });

    if (loading) {
        return <Loader />;
    }
    if (error) {
        errorsMessage.push('Невірно введені дані, або таке опитування вже існує');
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <BasicInput
                type="text"
                name="programName"
                label="Програма"
                margin="normal"
                value={formik.values.programName}
                onChange={formik.handleChange}
                error={formik.touched.programName && Boolean(formik.errors.programName)}
            />

            <BasicInput
                type="text"
                name="discipline"
                label="Дисципліна"
                margin="normal"
                value={formik.values.discipline}
                onChange={formik.handleChange}
                error={formik.touched.discipline && Boolean(formik.errors.discipline)}
            />
            <BasicInput
                type="text"
                name="group"
                label="Група"
                margin="normal"
                value={formik.values.group}
                onChange={formik.handleChange}
                error={formik.touched.group && Boolean(formik.errors.group)}
            />
            <p className="error-field">{errorsMessage[0]}</p>
            <BasicButton
                className={(classes.submitBtn, 'CreateSurveyForm__btn')}
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                fullWidth
                type="submit"
            >
                Створити
            </BasicButton>
            <LinkButton
                href="/surveys"
                className={classes.submitBtn}
                variant="outlined"
                color="secondary"
                fullWidth
            >
                Повернутись до Опитувань
            </LinkButton>
        </form>
    );
};

export default CreateSurveyForm;
