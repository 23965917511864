import * as yup from 'yup';

const validationSchema = yup.object({
    levelRate: yup.string().required(`Всі поля є обовя'зковими`),
    structureRate: yup.string().required(`Всі поля є обовя'зковими`),
    scheduleRate: yup.string().required(`Всі поля є обовя'зковими`),
    hardSkillsRate: yup.string().required(`Всі поля є обовя'зковими`),
    softSkillsRate: yup.string().required(`Всі поля є обовя'зковими`),
    lecturesQuantity: yup.string().required(`Всі поля є обовя'зковими`),
    lessonsQuantity: yup.string().required(`Всі поля є обовя'зковими`),
    literatureNeed: yup.string().required(`Всі поля є обовя'зковими`),
    materialQuantityRate: yup.string().required(`Всі поля є обовя'зковими`),
    toChangeSuggestions: yup
        .string()
        .min(3, 'Поле повино містити не менше 3 символів')
        .max(300, 'Поле повино містити не більше 300 символів')
        .required(`Всі поля є обовя'зковими`),
    // isDelay: yup.array().required(`Всі поля є обовя'зковими`),
    disciplineRate: yup.string().required(`Всі поля є обовя'зковими`),
    futureImportanceRate: yup.string().required(`Всі поля є обовя'зковими`),
    isSuggestedRate: yup.string().required(`Всі поля є обовя'зковими`),

    attentionSelect: yup
        .array()
        .test('test', "Всі поля є обовя'зковими ", function (attentionSelect) {
            const { attentionSelectElse } = this.parent;

            if (!attentionSelectElse && attentionSelect.length === 0) {
                return false;
            }
            return true;
        }),

    literatureProvide: yup
        .array()
        .test('test', "Всі поля є обовя'зковими ", function (literatureProvide) {
            const { literatureProvideElse } = this.parent;

            if (!literatureProvideElse && literatureProvide.length === 0) {
                return false;
            }
            return true;
        }),
    reasonSelect: yup.array().test('test', "Всі поля є обовя'зковими ", function (reasonSelect) {
        const { reasonSelectElse } = this.parent;

        if (!reasonSelectElse && reasonSelect.length === 0) {
            return false;
        }
        return true;
    }),
});

export default validationSchema;
