import React from 'react';

import Decoration from '../Decoration/Decoration';
import MainSidebar from './components/MainSidebar/MainSidebar';

const WithMainSidebar = ({
    className = '',
    noTopDecoration = false,
    noBottomDecoration = false,
    children,
}) => {
    return (
        <div className={`with-main-sidebar-page ${className}`}>
            <MainSidebar />
            <div className="with-main-sidebar-page__content">
                {!noTopDecoration && <Decoration top />}
                {children}
                {!noBottomDecoration && <Decoration bottom />}
            </div>
        </div>
    );
};

export default WithMainSidebar;
