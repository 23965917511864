import React from 'react';
import { Dialog, DialogActions, DialogTitle, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import BasicButton from '../Button/Button';

const useStyles = makeStyles(() => ({
    paper: {
        padding: 30,
    },
    title: {
        textTransform: 'uppercase',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',

        '& button': {
            width: '100%',
        },
    },
}));
const InfoDialog = ({ open, title, onClose, hideCloseBtn = false }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ className: clsx(classes.paper) }}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogActions className={classes.actions}>
                {!hideCloseBtn && (
                    <BasicButton onClick={onClose} color="secondary" variant="contained">
                        ЗАКРИТИ
                    </BasicButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
